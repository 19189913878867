import React from "react";

import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { useAppDispatch, useAppSelector } from "store";
import { hideRetailerFilters, RetailerFilterStep, selectActiveStep, selectCandidateRetailer, setActiveStep } from "modules/customer/tools/product/retailerFilters/retailerFiltersSlice";
import { chooseRetailerAndGroup } from "modules/customer/tools/product/productSlice";

const StyledButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(-1.5)
    }
}))(Button);

const Actions: React.FC = () => {
    const dispatch = useAppDispatch();
    const activeStep = useAppSelector(selectActiveStep);
    const candidateRetailer = useAppSelector(selectCandidateRetailer);

    const handleBackClick = () => {
        let previousStep: RetailerFilterStep;
        switch (activeStep) {
            case RetailerFilterStep.SelectRetailerGroup:
                previousStep = RetailerFilterStep.SelectRetailer;
                break;
            case RetailerFilterStep.SelectRetailer:
            default:
                previousStep = RetailerFilterStep.SelectRetailer;
                break;
        }
        dispatch(setActiveStep(previousStep));
    };

    const handleNextClick = () => {
        let nextStep: RetailerFilterStep;
        switch (activeStep) {
            case RetailerFilterStep.SelectRetailer:
                nextStep = RetailerFilterStep.SelectRetailerGroup;
                break;
            case RetailerFilterStep.SelectRetailerGroup:
            default:
                nextStep = RetailerFilterStep.SelectRetailerGroup;
                break;
        }
        dispatch(setActiveStep(nextStep));
    };

    const handleFinishClick = () => {
        if (!candidateRetailer /*|| !candidateRetailerGroup*/) {
            return;
        }
        const payload = {
            retailer: candidateRetailer,
            group: ""
        };
        dispatch(chooseRetailerAndGroup(payload));
        dispatch(hideRetailerFilters());
    };

    return (
        <>
            <StyledButton
                variant="text"
                size="medium"
                color="default"
                disableElevation
                onClick={handleBackClick}
                disabled={activeStep === RetailerFilterStep.SelectRetailer}
                data-cy="btn-back"
            >
                Back
            </StyledButton>
            {activeStep === RetailerFilterStep.SelectRetailer && (
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    disableElevation
                    onClick={handleNextClick}
                    disabled={!candidateRetailer}
                    data-cy="btn-next"
                >
                    Next
                </Button>
            )}
            {activeStep === RetailerFilterStep.SelectRetailerGroup && (
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    disableElevation
                    onClick={handleFinishClick}
                    //ToDo: Add finish button disabled logic
                    disabled={false}
                    data-cy="btn-finish"
                >
                    Finish
                </Button>
            )}
        </>
    );
};

export default Actions;
