import React from "react";

import { Button, Typography } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { withStyles } from "@material-ui/core/styles";

import SimpleTooltip, { SimpleTooltipPlacement } from "components/SimpleTooltip";

import {
    selectRetailerFiltersVisibility,
    showRetailerFilters,
} from "modules/customer/tools/product/retailerFilters/retailerFiltersSlice";
import { useAppDispatch, useAppSelector } from "store";
import { selectSelectedRetailer } from "modules/customer/tools/product/productSlice";

const StyledButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.common.white,
        "&:hover": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.background.default,
        }
    }
}))(Button);

const OpenRetailers: React.FC = () => {
    const dispatch = useAppDispatch();
    const filtersVisibility = useAppSelector(selectRetailerFiltersVisibility);
    const selectedRetailer = useAppSelector(selectSelectedRetailer);

    const handleClick = () => {
        dispatch(showRetailerFilters());
    };

    return (
        <>
            <SimpleTooltip
                placement={SimpleTooltipPlacement.Bottom}
                title={
                    <Typography variant="subtitle1" component="div">
                        Select a retailer to use the Product tool
                    </Typography>
                }
                open={!filtersVisibility.isVisible && !selectedRetailer}
                disableFocusListener={true}
                disableHoverListener={true}
                disableTouchListener={true}
            >
                <StyledButton
                    variant="outlined"
                    size="small"
                    color="default"
                    disableElevation
                    onClick={handleClick}
                    data-cy="btn-setup"
                >
                    <SettingsIcon />
                </StyledButton>
            </SimpleTooltip>
        </>
    );
};

export default OpenRetailers;
