import { combineReducers } from "redux";

import slice from "./productSlice";
import retailerFilters from "./retailerFilters";

const reducer = combineReducers({
    root: slice.reducer,
    retailerFilters
});

export default reducer;
