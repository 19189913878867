import React from "react";

import { Box, Button, Container, Divider, List, ListItem, ListItemText, ListSubheader, Typography } from "@material-ui/core";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import { useTheme } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import { CostChapter, selectCurrentChapter, setCurrentChapter } from "modules/customer/insights/cost/costSlice";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";
import { useAppDispatch, useAppSelector } from "store";

const Navigation: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const currentChapter = useAppSelector(selectCurrentChapter);
    const featureFlags = useAppSelector(selectFeatureFlags);

    const chooseChapter = (newChapter: CostChapter) => {
        dispatch(setCurrentChapter(newChapter));
    };

    return (
        // @ts-ignore
        <Box bgcolor={theme.palette.background.insight} height="100vh">
            <Container maxWidth={false}>
                <Box paddingTop={1} paddingBottom={2} display="flex" alignItems="center">
                    <Typography variant="h3" component="div">
                        Costs
                    </Typography>
                    <Spacer />
                    <Button
                        variant="outlined"
                        size="small"
                        color="default"
                        disableElevation
                    >
                        <SettingsRoundedIcon />
                    </Button>
                </Box>
                <Divider />
                <Box paddingTop={2} paddingBottom={2}>
                    <List disablePadding>
                        <ListSubheader disableGutters>
                            Estate
                        </ListSubheader>
                        <ListItem
                            button
                            selected={currentChapter === CostChapter.CostOverview}
                            onClick={() => chooseChapter(CostChapter.CostOverview)}
                        >
                            <ListItemText primary="Cost overview" />
                        </ListItem>
                        {featureFlags.enableCustomerInsightsCostCostDrivers && (
                            <ListItem
                                button
                                selected={currentChapter === CostChapter.CostDrivers}
                                onClick={() => chooseChapter(CostChapter.CostDrivers)}
                            >
                                <ListItemText primary="Cost drivers" />
                            </ListItem>
                        )}
                        {featureFlags.enableCustomerInsightsCostStoreCosts && (
                            <>
                                <ListSubheader disableGutters>
                                    Store
                                </ListSubheader>
                                <ListItem
                                    button
                                    selected={currentChapter === CostChapter.StoreCosts}
                                    onClick={() => chooseChapter(CostChapter.StoreCosts)}
                                >
                                    <ListItemText primary="Store costs" />
                                </ListItem>
                            </>
                        )}
                    </List>
                </Box>
            </Container>
        </Box>
    );
};

export default Navigation;
