import React from "react";

import { Box, Card, CardActionArea, CardActions, CardContent, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { Retailer } from "modules/customer/tools/product/retailer";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        height: "100%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: (props: any) => props["data-selected"] ? theme.palette.common.white : "transparent"
    }
}))(Card);

const StyledCardActionArea = withStyles(() => ({
    root: {
        height: "100%"
    }
}))(CardActionArea);

const StyledCardContent = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light
    }
}))(CardContent);

// const StyledTypography = withStyles(theme => ({
//     root: {
//         color: theme.palette.text.disabled
//     }
// }))(Typography);

interface RetailerCardProps {
    retailer: Retailer,
    selected: boolean,
    onSelect: (retailer: Retailer) => void,
    onDeselect: (retailer: Retailer) => void
}

const RetailerCard: React.FC<RetailerCardProps> = (props) => {
    const { retailer, selected, onSelect, onDeselect } = props;

    const handleClick = () => {
        if (selected) {
            onDeselect(retailer);
        } else {
            onSelect(retailer);
        }
    };

    return (
        <StyledCard elevation={0} data-selected={selected}>
            <StyledCardActionArea onClick={handleClick} disableRipple disableTouchRipple>
                <StyledCardContent>
                    <h3>{retailer.name}</h3>
                    {/* <RadarChart retailer={retailer} enableSpend={enableSpend} /> */}
                </StyledCardContent>
                <CardActions>
                    <Box>
                        <Typography variant="subtitle1" component="div">
                            {retailer.name}
                        </Typography>
                        {/* @ts-ignore */}
                        {/* <StyledTypography variant="body1" component="div">
                            {retailer.categoryName}
                        </StyledTypography> */}
                    </Box>
                </CardActions>
            </StyledCardActionArea>
        </StyledCard>
    );
};

export default RetailerCard;
