import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { RootState } from "store";
import { Retailer } from "modules/customer/tools/product/retailer";
import { selectRetailers } from "modules/customer/tools/product/productSlice";

interface FiltersVisibility {
    isVisible: boolean
}

export enum RetailerFilterStep {
    SelectRetailer,
    SelectRetailerGroup
}

export interface RetailersSearch {
    name: string
}

interface FiltersState {
    retailerFiltersVisibility: FiltersVisibility,
    activeStep: RetailerFilterStep,
    retailersSearch: RetailersSearch,
    candidateRetailer?: Retailer,
    candidateRetailerGroup?: string
}

const initialState: FiltersState = {
    retailerFiltersVisibility: {
        isVisible: false
    },
    activeStep: RetailerFilterStep.SelectRetailer,
    retailersSearch: {
        name: ""
    },
    candidateRetailer: undefined,
    candidateRetailerGroup: undefined
};

const retailerFiltersSlice = createSlice({
    name: "customer/tools/location/filters",
    initialState,
    reducers: {
        showRetailerFilters: (state) => {
            state.activeStep = initialState.activeStep;
            state.retailerFiltersVisibility.isVisible = true;
        },
        hideRetailerFilters: (state) => {
            state.retailerFiltersVisibility.isVisible = false;
        },
        setActiveStep: (state, action: PayloadAction<RetailerFilterStep>) => {
            state.activeStep = action.payload;
        },
        clearActiveStep: (state) => {
            state.activeStep = initialState.activeStep;
        },
        setRetailersSearch: (state, action: PayloadAction<RetailersSearch>) => {
            state.retailersSearch = action.payload;
        },
        clearRetailersSearch: (state) => {
            state.retailersSearch = initialState.retailersSearch;
        },
        setCandidateRetailer: (state, action: PayloadAction<Retailer>) => {
            state.candidateRetailer = action.payload;
        },
        clearCandidateRetailer: (state) => {
            state.candidateRetailer = initialState.candidateRetailer;
        },
        setCandidateRetailerGroup: (state, action: PayloadAction<string>) => {
            state.candidateRetailerGroup = action.payload;
        },
        clearCandidateRetailerGroup: (state) => {
            state.candidateRetailerGroup = initialState.candidateRetailerGroup;
        }
    },
    extraReducers: (builder: any) => {

    }
});

export const {
    showRetailerFilters,
    hideRetailerFilters,
    setActiveStep,
    setRetailersSearch,
    setCandidateRetailer,
    setCandidateRetailerGroup,
    clearActiveStep,
    clearCandidateRetailer,
    clearCandidateRetailerGroup,
    clearRetailersSearch
} = retailerFiltersSlice.actions;

export const clearRetailerFilters = (): AppThunk => async (dispatch) => {
    dispatch(retailerFiltersSlice.actions.hideRetailerFilters());
    dispatch(retailerFiltersSlice.actions.clearActiveStep());
    dispatch(retailerFiltersSlice.actions.clearRetailersSearch());
    dispatch(retailerFiltersSlice.actions.clearCandidateRetailer());
    dispatch(retailerFiltersSlice.actions.clearCandidateRetailerGroup());
};

export const selectRetailerFiltersVisibility = (state: RootState): FiltersVisibility => {
    return state.customer.tools.product.retailerFilters.retailerFiltersVisibility;
};

export const selectActiveStep = (state: RootState) => {
    return state.customer.tools.product.retailerFilters.activeStep;
};

export const selectRetailersSearch = (state: RootState) => {
    return state.customer.tools.product.retailerFilters.retailersSearch;
};

export const selectCandidateRetailer = (state: RootState) => {
    return state.customer.tools.product.retailerFilters.candidateRetailer;
};

export const selectCandidateRetailerGroup = (state: RootState) => {
    return state.customer.tools.product.retailerFilters.candidateRetailerGroup;
};

export const selectFilteredRetailers = createSelector(
    (state: RootState) => selectRetailers(state),
    selectRetailersSearch,
    // selectFilter,
    // selectSort,
    (retailers, search/*, filter, sort*/) => {
        const searchString = search.name.toLowerCase();
        const filteredRetailers = retailers.filter(retailer =>
            (!searchString || retailer.name.toLowerCase().includes(searchString)));
        return filteredRetailers;
    }
);


export default retailerFiltersSlice;
