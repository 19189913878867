import React from "react";
import _ from "lodash";

import { Box, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";

import {
    selectRetailersSearch,
    setRetailersSearch,
    RetailersSearch
} from "modules/customer/tools/product/retailerFilters/retailerFiltersSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledTextField = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        width: theme.spacing(45)
    }
}))(TextField);

const Search = () => {
    const dispatch = useAppDispatch();
    const retailersSearch = useAppSelector(selectRetailersSearch);
    const [currentRetailersSearch, setCurrentRetailersSearch] = React.useState<RetailersSearch>(retailersSearch);

    const setRetailersSearchDelayed = React.useMemo(() => {
        return _.debounce((retailersSearch: RetailersSearch) => dispatch(setRetailersSearch(retailersSearch)), 400);
    }, [dispatch]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        const newRetailersSearch = { ...retailersSearch, name };
        setCurrentRetailersSearch(newRetailersSearch);
        setRetailersSearchDelayed(newRetailersSearch);
    };

    React.useEffect(() => {
        setCurrentRetailersSearch(retailersSearch);
    }, [retailersSearch]);

    return (
        <Box paddingTop={2}>
            <StyledTextField
                placeholder="Search"
                variant="outlined"
                size="small"
                color="secondary"
                value={currentRetailersSearch.name}
                onChange={handleChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    );
};

export default Search;
