import React from "react";

import { Box, Typography } from "@material-ui/core";

const Title: React.FC = () => {
    return (
        <>
            <Typography variant="h5" component="div">
                Retailer
            </Typography>
            <Box paddingTop={2}>
                <Typography variant="subtitle1" component="div">
                    Explore the opportunity values for new or existing retail partners using the tiles below.
                    Additionally, you can see the split of headroom for B2B and B2C sales activities
                </Typography>
            </Box>
        </>
    );
};

export default Title;
