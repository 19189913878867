import React from "react";

import { RetailerFilterStep, selectActiveStep } from "modules/customer/tools/product/retailerFilters/retailerFiltersSlice";
import { useAppSelector } from "store";
import RetailerFascias from "./retailerFascias/RetailerFascias";

const Content: React.FC = () => {
    const activeStep = useAppSelector(selectActiveStep);

    return (
        <>
            {activeStep === RetailerFilterStep.SelectRetailer && (<RetailerFascias />)}
            {activeStep === RetailerFilterStep.SelectRetailerGroup && (<p>Select retail partner group content</p>)}
        </>
    );
};

export default Content;
