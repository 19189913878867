import React from "react";

import { Grid } from "@material-ui/core";

import Insight from "./insight/Insight";
import Navigation from "./navigation/Navigation";
import { clearProduct, loadProduct, selectSelectedRetailer } from "modules/customer/tools/product/productSlice";
import { useAppDispatch, useAppSelector } from "store";
import { showRetailerFilters } from "modules/customer/tools/product/retailerFilters/retailerFiltersSlice";

const Product: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectedRetailer = useAppSelector(selectSelectedRetailer);
    const isSetupComplete = !!selectedRetailer;

    React.useEffect(() => {
        dispatch(loadProduct());
        dispatch(showRetailerFilters());
        return () => {
            dispatch(clearProduct());
        };
    }, [dispatch]);
    return (
        <Grid container data-cy="pages-customer-tools-product">
            <Grid item xs={3}>
                <Navigation isSetupComplete={isSetupComplete} />
            </Grid>
            <Grid item xs={9}>
                <Insight isSetupComplete={isSetupComplete} />
            </Grid>
        </Grid>
    );
};

export default Product;
