import React from "react";

import { Grid } from "@material-ui/core";

import Navigation from "./navigation/Navigation";
import Insight from "./insight/Insight";

const Cost: React.FC = () => {
    return (
        <Grid container data-cy="pages-customer-tools-product">
            <Grid item xs={3}>
                <Navigation />
            </Grid>
            <Grid item xs={9}>
                <Insight />
            </Grid>
        </Grid>
    );
};

export default Cost;
