import { Query } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { apiGet } from "modules/helpers/api/apiSlice";
import { cubeLoadParallel } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import { StoreCategory } from "./storeCategory";
import { SpendCategory } from "./spendCategory";

export enum ScoreField {
    Overall = "overallScore",
    Demographics = "demographicsScore",
    Spend = "spendScore",
    AreaHealth = "areaHealthScore",
    Footfall = "footfallScore"
}

export class StaticRetailCentre {
    public readonly id: number;
    public readonly code: string;
    public readonly isInRetailCentre: boolean;
    public readonly regionCode: string;
    public readonly regionName: string;
    public readonly localAuthorityCode: string;
    public readonly latitude: number;
    public readonly longitude: number;

    constructor(
        id: number,
        code: string,
        isInRetailCentre: boolean,
        regionCode: string,
        regionName: string,
        localAuthorityCode: string,
        latitude: number,
        longitude: number
    ) {
        this.id = id;
        this.code = code;
        this.isInRetailCentre = isInRetailCentre;
        this.regionCode = regionCode;
        this.regionName = regionName;
        this.localAuthorityCode = localAuthorityCode;
        this.latitude = latitude;
        this.longitude = longitude;
    }
}

export class RetailCentre extends StaticRetailCentre {
    public readonly storeCategoryId: number;
    public readonly storeCategoryName: string;
    public readonly affluenceCentile: number;
    public readonly ageCentile: number;
    public readonly childrenCentile: number;
    public readonly diversityCentile: number;
    public readonly urbanicityCentile: number;
    public readonly spendCentile: number;
    public readonly areaHealthCentile: number;
    public readonly emptyAreaHealth: boolean;
    public readonly footfallCentile: number;

    constructor(
        staticRetailCentre: StaticRetailCentre,
        storeCategoryId: number,
        storeCategoryName: string,
        affluenceCentile: number,
        ageCentile: number,
        childrenCentile: number,
        diversityCentile: number,
        urbanicityCentile: number,
        spendCentile: number,
        areaHealthCentile: number,
        emptyAreaHealth: boolean,
        footfallCentile: number
    ) {
        super(
            staticRetailCentre.id,
            staticRetailCentre.code,
            staticRetailCentre.isInRetailCentre,
            staticRetailCentre.regionCode,
            staticRetailCentre.regionName,
            staticRetailCentre.localAuthorityCode,
            staticRetailCentre.latitude,
            staticRetailCentre.longitude
        );
        this.storeCategoryId = storeCategoryId;
        this.storeCategoryName = storeCategoryName;
        this.affluenceCentile = affluenceCentile;
        this.ageCentile = ageCentile;
        this.childrenCentile = childrenCentile;
        this.diversityCentile = diversityCentile;
        this.urbanicityCentile = urbanicityCentile;
        this.spendCentile = spendCentile;
        this.areaHealthCentile = areaHealthCentile;
        this.emptyAreaHealth = emptyAreaHealth;
        this.footfallCentile = footfallCentile;
    }
}

export class RetailCentreWithScores extends RetailCentre {
    public readonly overallScore: number;
    public readonly demographicsScore: number;
    public readonly spendScore: number;
    public readonly areaHealthScore: number;
    public readonly footfallScore: number;

    constructor(
        retailCentre: RetailCentre,
        overallScore: number,
        demographicsScore: number,
        spendScore: number,
        areaHealthScore: number,
        footfallScore: number
    ) {
        super(
            retailCentre,
            retailCentre.storeCategoryId,
            retailCentre.storeCategoryName,
            retailCentre.affluenceCentile,
            retailCentre.ageCentile,
            retailCentre.childrenCentile,
            retailCentre.diversityCentile,
            retailCentre.urbanicityCentile,
            retailCentre.spendCentile,
            retailCentre.areaHealthCentile,
            retailCentre.emptyAreaHealth,
            retailCentre.footfallCentile
        );
        this.overallScore = overallScore;
        this.demographicsScore = demographicsScore;
        this.spendScore = spendScore;
        this.areaHealthScore = areaHealthScore;
        this.footfallScore = footfallScore;
    }

    getRagScore = (scoreField: ScoreField): number => {
        const alignmentScore = this[scoreField];
        let threshold = 3;
        for (let i = 0; i <= 5; i++) {
            if (alignmentScore < threshold) {
                return i;
            }
            threshold += 1.5;
        }
        return 5;
    };
}

export const loadStaticRetailCentres = (): AppThunk<Promise<Map<number, StaticRetailCentre>>> => async (dispatch) => {
    try {
        const downloadUrlResponse = await dispatch(apiGet("/customer/tools/location/retail-centres"));
        const downloadUrl = downloadUrlResponse.data.url;
        const staticFileResponse = await dispatch(apiGet(downloadUrl));
        const retailCentresFromFile = staticFileResponse?.data ?? [];
        const staticRetailCentres = new Map<number, StaticRetailCentre>();
        retailCentresFromFile.forEach((retailCentreFromFile: any) => {
            const retailCentreId = Number(retailCentreFromFile.id);
            const staticRetailCentre = new StaticRetailCentre(
                retailCentreId,
                String(retailCentreFromFile.code),
                Boolean(retailCentreFromFile.isInRetailCentre),
                String(retailCentreFromFile.regionCode),
                String(retailCentreFromFile.regionName),
                String(retailCentreFromFile.localAuthorityCode),
                Number(retailCentreFromFile.latitude),
                Number(retailCentreFromFile.longitude));
            staticRetailCentres.set(retailCentreId, staticRetailCentre);
        });
        return staticRetailCentres;
    } catch (error) {
        dispatch(logError("Error loading StaticRetailCentres.", error));
        throw error;
    }
};

export const loadRetailCentres = (
    staticRetailCentres: Map<number, StaticRetailCentre>,
    targetStoreCategory: StoreCategory | undefined,
    targetSpendCategories: SpendCategory[],
    enableSpendNew: boolean,
    scenarioCatchmentAccountId: string
): AppThunk<Promise<RetailCentre[]>> => async (dispatch) => {
    if (!targetStoreCategory || staticRetailCentres.size === 0) {
        return [];
    }

    try {
        const query: Query = {
            dimensions: [
                "LocationBenchmarkMetrics.RetailCentreID",
                "LocationBenchmarkMetrics.AffluenceCentile",
                "LocationBenchmarkMetrics.AgeCentile",
                "LocationBenchmarkMetrics.ChildrenCentile",
                "LocationBenchmarkMetrics.DiversityCentile",
                "LocationBenchmarkMetrics.UrbanicityCentile",
                "LocationBenchmarkMetrics.AreaHealthCentile",
                "LocationBenchmarkMetrics.YoYNetOpeningsPercentage",
                "LocationBenchmarkMetrics.FootfallCentile"
            ],
            filters: [{
                member: "LocationBenchmarkMetrics.StoreCategory_ID",
                operator: "equals",
                values: [String(targetStoreCategory.id)]
            }, {
                member: "LocationBenchmarkMetrics.Client_ID",
                operator: "equals",
                values: [scenarioCatchmentAccountId]
            }],
            segments: [
                "LocationBenchmarkMetrics.Scenario",
            ],
            order: {
                "LocationBenchmarkMetrics.RetailCentreID": "asc",
            }
        };

        if (enableSpendNew) {
            query.measures = ["LocationBenchmarkSpendMetrics.MeanSpendCentile"];
            query.filters?.push({
                member: "LocationBenchmarkSpendMetrics.SpendCategory_ID",
                operator: "equals",
                values: targetSpendCategories.map(spendCategory => String(spendCategory.id))
            });
        }

        const resultSet = await dispatch(cubeLoadParallel(query, staticRetailCentres.size));

        const retailCentres: RetailCentre[] = [];
        resultSet.forEach(item => {
            const retailCentreId = Number(item["LocationBenchmarkMetrics.RetailCentreID"] ?? 0);
            const staticRetailCentre = staticRetailCentres.get(retailCentreId);
            if (staticRetailCentre) {
                const retailCentre = new RetailCentre(
                    staticRetailCentre,
                    targetStoreCategory.id,
                    targetStoreCategory.name,
                    Number(item["LocationBenchmarkMetrics.AffluenceCentile"] ?? 0),
                    Number(item["LocationBenchmarkMetrics.AgeCentile"] ?? 0),
                    Number(item["LocationBenchmarkMetrics.ChildrenCentile"] ?? 0),
                    Number(item["LocationBenchmarkMetrics.DiversityCentile"] ?? 0),
                    Number(item["LocationBenchmarkMetrics.UrbanicityCentile"] ?? 0),
                    enableSpendNew ? Number(item["LocationBenchmarkSpendMetrics.MeanSpendCentile"] ?? 0) : 0,
                    Number(item["LocationBenchmarkMetrics.AreaHealthCentile"] ?? 0),
                    Boolean(item["LocationBenchmarkMetrics.YoYNetOpeningsPercentage"] === null),
                    Number(item["LocationBenchmarkMetrics.FootfallCentile"] ?? 0)
                );
                retailCentres.push(retailCentre);
            }
        });
        return retailCentres;
    } catch (error) {
        dispatch(logError("Error loading RetailCentres.", error));
        throw error;
    }
};
