// import { Query, ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
// import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";


export class Retailer {
    public readonly id: number;
    public readonly name: string;

    constructor(
        id: number,
        name: string
    ) {
        this.id = id;
        this.name = name;
    }
}

export const loadRetailers = (): AppThunk<Promise<Retailer[]>> => async (dispatch) => {
    try {
        return [
            new Retailer(0, "Spar"),
            new Retailer(1, "Tesco"),
            new Retailer(2, "Co-op"),
        ]; //ToDo: Load retailers
    } catch (error) {
        dispatch(logError("Error loading Retailers.", error));
        throw error;
    }
};
