import React from "react";

import { Stepper as MuiStepper, Step, StepContent, StepLabel, Typography, withStyles } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";

import StepIcon from "./StepIcon";

import { useAppSelector } from "store";
import { selectActiveStep, selectCandidateRetailer } from "modules/customer/tools/product/retailerFilters/retailerFiltersSlice";

const StyledStepper = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark,
        paddingLeft: theme.spacing(0)
    }
}))(MuiStepper);

const Stepper: React.FC = () => {
    const colourPalette = useColourPalette();
    const activeStep = useAppSelector(selectActiveStep);
    const candidateRetailer = useAppSelector(selectCandidateRetailer);

    return (
        <StyledStepper activeStep={activeStep} orientation="vertical">
            <Step expanded>
                <StepLabel StepIconComponent={StepIcon}>
                    <Typography variant="subtitle1" component="div">
                        Select a retailer
                    </Typography>
                </StepLabel>
                <StepContent style={{ color: colourPalette.comparators[0] }}>
                    <Typography variant="h6" component="div" noWrap>
                        {candidateRetailer?.name}
                    </Typography>
                </StepContent>
            </Step>
            <Step expanded>
                <StepLabel StepIconComponent={StepIcon}>
                    <Typography variant="subtitle1" component="div">
                        Select a retailer group
                    </Typography>
                </StepLabel>
            </Step>
        </StyledStepper>
    );
};

export default Stepper;
