import React from "react";
import InfiniteScroll from "react-infinite-scroller";

import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import Search from "./Search";
import RetailerCard from "./RetailerCard";
import Title from "./Title";
import { Retailer } from "modules/customer/tools/product/retailer";
import { useAppDispatch, useAppSelector } from "store";
import { clearCandidateRetailer, selectCandidateRetailer, selectFilteredRetailers, setCandidateRetailer } from "modules/customer/tools/product/retailerFilters/retailerFiltersSlice";

const NUMBER_OF_VISIBLE_RETAILERS_INCREMENT = 15;

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark
    }
}))(Card);

const RetailerFascias: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const candidateRetailer = useAppSelector(selectCandidateRetailer);
    const [numberOfVisibleRetailers, setNumberOfVisibleRetailers] = React.useState<number>(0);
    const retailers = useAppSelector(selectFilteredRetailers);
    const retailersLabel = `${retailers.length} ${retailers.length === 1 ? "retailer" : "retailers"}`;

    const visibleRetailers = React.useMemo(() => {
        return retailers.slice(0, numberOfVisibleRetailers);
    }, [retailers, numberOfVisibleRetailers]);

    const handleSelect = (retailer: Retailer) => {
        dispatch(setCandidateRetailer(retailer));
    };

    const handleDeselect = () => {
        dispatch(clearCandidateRetailer());
    };

    const handleLoadMore = React.useCallback(() => {
        setNumberOfVisibleRetailers(previousNumberOfVisibleRetailers => previousNumberOfVisibleRetailers + NUMBER_OF_VISIBLE_RETAILERS_INCREMENT);
    }, [setNumberOfVisibleRetailers]);

    React.useEffect(() => {
        setNumberOfVisibleRetailers(NUMBER_OF_VISIBLE_RETAILERS_INCREMENT);
    }, [retailers]);

    return (
        <InfiniteScroll
            initialLoad={false}
            loadMore={handleLoadMore}
            hasMore={numberOfVisibleRetailers <= retailers.length}
            useWindow={false}
        >
            <Box marginBottom={4}>
                <Box position="sticky" top={0} bgcolor={theme.palette.background.paper} zIndex={theme.zIndex.drawer}>
                    <Title />
                    <Search />
                    <Box display="flex" alignItems="center" paddingTop={2} paddingBottom={0.5}>
                        <Typography variant="body1" component="div">
                            &nbsp;&nbsp;{retailersLabel}
                        </Typography>
                        <Spacer />
                    </Box>
                </Box>
                {visibleRetailers.length > 0 && (
                    <StyledCard elevation={0}>
                        <CardContent>
                            <Grid container spacing={2}>
                                {visibleRetailers.map(retailer =>
                                    <Grid key={retailer.id} item xs={4}>
                                        <RetailerCard
                                            retailer={retailer}
                                            selected={candidateRetailer?.id === retailer.id}
                                            onSelect={handleSelect}
                                            onDeselect={handleDeselect}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </StyledCard>
                )}
            </Box>
        </InfiniteScroll>
    );
};

export default RetailerFascias;
