import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { backdropOff, backdropOn } from "modules/backdrop/backdropSlice";
import { setupCube } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";
import { loadRetailers, Retailer } from "./retailer";
import { clearRetailerFilters } from "./retailerFilters/retailerFiltersSlice";

export enum ProductChapter {
    ProductOverview = 1,
    ProductOpportunities,
    StoreOverview,
    StoreOpportunities,
    ProductStoreFit
}

interface LoadProductResponse {
    retailers: Retailer[]
}

interface ProductState {
    isLoading: boolean,
    hasErrors: boolean,
    currentChapter: ProductChapter,
    retailers: Retailer[],
    selectedRetailer?: Retailer
}

const initialState: ProductState = {
    isLoading: false,
    hasErrors: false,
    currentChapter: ProductChapter.ProductOverview,
    retailers: [],
    selectedRetailer: undefined
};

const productSlice = createSlice({
    name: "customer/tools/product",
    initialState,
    reducers: {
        setCurrentChapter: (state, action: PayloadAction<ProductChapter>) => {
            state.currentChapter = action.payload;
        },
        resetCurrentChapter: (state) => {
            state.currentChapter = initialState.currentChapter;
        },
        chooseRetailerAndGroup: (state, action: PayloadAction<{ retailer: Retailer, group: string }>) => {
            state.selectedRetailer = action.payload.retailer;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadProduct.pending, (state: ProductState) => {
            state.isLoading = true;
            state.hasErrors = false;
        });
        builder.addCase(loadProduct.rejected, (state: ProductState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.retailers = initialState.retailers;
        });
        builder.addCase(loadProduct.fulfilled, (state: ProductState, action: PayloadAction<LoadProductResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.retailers = action.payload.retailers;
        });
    }
});

export const {
    setCurrentChapter,
    chooseRetailerAndGroup
} = productSlice.actions;

export const loadProduct = createAppAsyncThunk(
    "customer/tools/location/loadProduct",
    async (arg, thunkAPI) => {
        thunkAPI.dispatch(backdropOn());
        try {
            await thunkAPI.dispatch(setupCube());
            const retailers = await thunkAPI.dispatch(loadRetailers());
            const loadProductResponse: LoadProductResponse = {
                retailers
            };
            return loadProductResponse;
        } catch (error) {
            thunkAPI.dispatch(notifyError("Error loading Product."));
            return thunkAPI.rejectWithValue(null);
        } finally {
            thunkAPI.dispatch(backdropOff());
        }
    }
);

export const clearProduct = (): AppThunk => async (dispatch) => {
    dispatch(productSlice.actions.resetCurrentChapter());
    dispatch(clearRetailerFilters());
    dispatch(clearInsights());
};

export const loadInsights = (): AppThunk => async (dispatch, getState) => {
    try {
        //ToDo: load insights
    } catch (error) {
        dispatch(logError("Error loading Insights.", error));
    }
};

export const clearInsights = (): AppThunk => (dispatch) => {
    //ToDo: clear insights
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.tools.product.root.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.tools.product.root.hasErrors;
};

export const selectCurrentChapter = (state: RootState) => {
    return state.customer.tools.product.root.currentChapter;
};

export const selectRetailers = (state: RootState) => {
    return state.customer.tools.product.root.retailers;
};

export const selectSelectedRetailer = (state: RootState) => {
    return state.customer.tools.product.root.selectedRetailer;
};

export default productSlice;
